import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AulasComponent } from './aulas/aulas.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JogosComponent } from './jogos/jogos.component';
import { LoginComponent } from './login/login.component';
import { PlayerComponent } from './player/player.component';
import { PrincipalComponent } from './principal/principal.component';
import { UsuariosComponent } from './usuarios/usuarios.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'login',
    component: LoginComponent,
  }, {
    path: 'aulas',
    children: [
      {
        path: '',
        component: AulasComponent,
      },
      {
        path: 'player/:idjogos',
        component: PlayerComponent,
      },
    ]
  }, {
    path: 'dashboard',
    component: PrincipalComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
      }, {
        path: 'usuarios',
        component: UsuariosComponent,
      }, {
        path: 'jogos',
        component: JogosComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export class LoginModel {
  usuario: string;
  senha: string;
}

export class ResponseModel {
  status: number;
  error: ErrorModel;
  response: POSTResult;
}

export class POSTResult {
  affectedRows: number;
  changedRows: number;
  fieldCount: number;
  insertId: number;
  message: string;
  protocol41: boolean;
  serverStatus: number;
  warningCount: number;
}

export class ErrorModel {
  code: string;
  errno: number;
  index: number;
  sql: string;
  sqlMessage: string;
  sqlState: string;
}
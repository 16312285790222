import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModel } from './loginModel';
import { LoginService } from './loginService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  FormLogin: LoginModel = new LoginModel();

  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {

  }

  btnEntrarClick() {
    if (this.FormLogin.usuario == "" || this.FormLogin.senha == "") {
      alert("Preencha as informações corretamente");
      return;
    }
    this.loginService.Login(this.FormLogin).then(resLogin => {
      if (resLogin) {
        let Name = resLogin.nome;
        if (Name.split(" ").length > 1) {
          let alunoName = resLogin.nome.split(" ");
          let alunoCaptular = alunoName[1].split("");
          Name = `${alunoName[0]} ${alunoCaptular[0]}.`;
        }
        localStorage.setItem("name", Name);
        localStorage.setItem("idusuarios", resLogin.idusuarios.toString());
        if (resLogin.idtipousuario == 1)
          this.router.navigate(['dashboard']);
        else if (resLogin.idtipousuario == 2)
          this.router.navigate(['aulas']);

      }
      else {
        alert("Usuário e/ou senha incorretos");
      }
    });
  }

}
